.lato {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.card {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 2px 2px 2px black;
  color: white;
  border: none;
  &:hover {
    box-shadow: 2px 2px 5px black;
    scale: 1.025;
  }
}

.card-title {
  font-size: 4rem;
}

body {
  min-height: 100vh;
  background: rgb(48, 32, 58) 0%;
  color: white;
}

.error-message {
  color: red;
}

.navbar {
  --bs-navbar-active-color: red;
  --bs-navbar-hover-color: red;
}
